
.main {
    background: url(../pictures/dark-rosie-fraser.jpg); 
    padding: 50px;
    background-size: cover;
    background-attachment: fixed;
    height: auto;
}
@media (min-width: 1270px) {
    .main {
        padding: 200px;
        height: auto;
    }
}
@media (min-width: 768px) {
    .main {
        padding: 100px;
        height: auto;
    }
}
@media (max-width: 768px) {
    .main {
        padding: 30px;
        height: auto;
    }
}
@media (max-width: 540px) {
    .main {
        padding: 20px;
        height: auto;
    }
}
@media (max-width: 320px) {
    .main {
        padding: 10px;
        height: auto;
    }
}

.main__p, .main__h {
    color: tan;
}

.main__h {
    font-size: 30px;
    font-family: 'Megrim';
    font-weight: bold;
    /* position: fixed; */
}
@media (max-width: 540px) {
    .main__h {
        font-size: 20px;
    }
}
@media (max-width: 320px) {
    .main__h {
        font-size: 15px;
    }
}

.main__p {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Srisakdi';
    letter-spacing: 3px;
}
@media (max-width: 540px) {
    .main__p {
        font-size: 20px;
    }
}
@media (max-width: 320px) {
    .main__p {
        font-size: 15px;
    }
}

.main__textarea {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px); 
    border: 3px solid rgba(240, 54, 54, 0.89);
    color: tan; 
    font-size: 40px;
    outline: none;
    padding: 20px;
    font-family: 'Calligraffitti';
    border-radius: 5px;
    resize: none;
    min-height: 20%;
    width: 80%;
    letter-spacing: 5px;
}
@media (max-width: 540px) {
    .main__textarea {
        font-size: 30px;
    }
}
@media (max-width: 320px) {
    .main__textarea {
        font-size: 20px;
    }
}
.main__textarea::placeholder {
    color: rgba(210, 180, 140, 0.473);
}
@media (max-width: 540px) {
    .main__textarea::placeholder {
        font-size: 30px;
    }
}
@media (max-width: 320px) {
    .main__textarea::placeholder {
        font-size: 20px;
    }
}

.main__button {
    font-size: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: black; 
    color: gray;
    margin: 30px;
    border-radius: 5px;
    border: 1px solid tan;
    outline: none; 
    cursor: pointer;
    font-family: 'Megrim';
    font-weight: bold;
}
.main__button:hover {
    color: tan;
}
@media (max-width: 540px) {
    .main__button {
        font-size: 20px;
        margin: 20px;
    }
}
@media (max-width: 320px) {
    .main__button {
        font-size: 15px;
        margin: 10px;
    }
}

.main__letters {
    font-family: 'Calligraffitti';
    letter-spacing: 30px;
    font-size: 50px;
    color: rgba(240, 54, 54, 0.89);
    display: inline;
    flex-wrap: wrap;
    overflow-x: hidden;
    justify-content: center;
    overflow-wrap: break-word;
}
@media (max-width: 540px) {
    .main__letters {
        font-size: 30px;
    }
}
@media (max-width: 320px) {
    .main__letters {
        font-size: 20px;
    }
}
