.app {
  text-align: center;
}

.app__header {
  background-color: #282c34;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: 'Megrim';
  letter-spacing: 20px;
  border-bottom: 2px solid rgba(240, 54, 54, 0.89);
}

.app__logo {
  height: 10vmin;
  pointer-events: none;
  margin: 15px;
}
@media (prefers-reduced-motion: no-preference) {
  .app__logo {
    animation: App-logo-spin infinite 30s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app__h {
  font-size: 60px;
  color: tan; 
  margin: 30px;
}
@media (max-width: 540px) {
  .app__h {
      font-size: 40px;
      margin: 20px;
  }
}
@media (max-width: 320px) {
  .app__h {
      font-size: 20px;
      margin: 10px;
  }
}
.app__h span {
  transition: 1s;
}
.app__h:hover span:nth-child(1) {
  margin-right: 5px;
}
.app__h:hover span:nth-child(1):after {
  content: 'O';
}

.app__h:hover span:nth-child(2) {
  margin-right: 5px;
}
.app__h:hover span:nth-child(2):after {
  content: 'E';
}

.app__h:hover span:nth-child(3) {
  margin-right: 5px;
}
.app__h:hover span:nth-child(3):after {
  content: ' ';
}

.app__h:hover span:nth-child(4) {
  margin-right: 5px;
}
.app__h:hover span:nth-child(4):after {
  content: 'I';
}

.app__h:hover span:nth-child(5) {
  margin-right: 5px;
}
.app__h:hover span:nth-child(5):after {
  content: 'I';
}

.app__h:hover span:nth-child(6) {
  margin-right: 5px; 
}
.app__h:hover span:nth-child(6):after {
  content: 'S';
}


.app__footer {
  background-color: #282c34;
  padding: 20px;
  border-top: 2px solid rgba(240, 54, 54, 0.89);
  color: tan;
  font-family: 'Megrim';
  font-weight: bold;
  font-size: 20px;
}
